.separator{
    background-image: linear-gradient(
        to right top, 
        #d16ba5,
        #c777b9, 
        #ba83ca, 
        #aa8fd8, 
        #9a9ae1, 
        #8aa7ec, 
        #79b3f4, 
        #69bff8, 
        #52cffe, 
        #41dfff, 
        #46eefa, 
        #5ffbf1
    );

    height: 6px;
    width: 40%;
    border-radius: 3px;
    margin: 16px 0px;
}